import { useMemo } from 'react';
import { Autocomplete } from '@mui/material';
import { ADDRESS, COUNTY_LIST } from 'data/addressData';

export default function SelectCounty({ preSelectZip, ...props }) {
  const options = useMemo(() => {
    if (!preSelectZip) return COUNTY_LIST;

    return [ADDRESS.find(e => e.towns.find(k => k.zipCode === preSelectZip))?.countyName];
  }, [preSelectZip]);
  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option}
      freeSolo
      isOptionEqualToValue={(option, value) => {
        if (value === '') return false;

        return option === value;
      }}
      {...props}
    />
  );
}

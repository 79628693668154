import { useState, useEffect, SyntheticEvent } from "react";
import { Autocomplete, TextField, TextFieldProps, Stack, Typography } from "@mui/material";
import { useUserService } from "service";

// interface User {
//   userId: string;
//   isDoctor: number;
//   realName: string;
//   salePointId?: string;
//   departmentId?: string;
//   department: API.Department;
// }

type User = API.User;

interface SelectProps<T> {
  value?: T | T[];
  onChange?(newValue: T | T[]): void;
  query?: any;
  multiple?: boolean;
  textFieldProps?: TextFieldProps;
  [x: string]: any;
}

function defaultState(value?: User | User[], multiple?: boolean) {
  if (multiple) {
    return value || [];
  }
  return value;
}

export default function SelectUser({ value, onChange, query, multiple, textFieldProps, ...props }: SelectProps<User>): JSX.Element {
  const { useAllUser } = useUserService();

  const { data, isLoading } = useAllUser({ query: new URLSearchParams({ isBlocked: 0, ...query }) });

  const [user, setUser] = useState(defaultState(value, multiple));

  const handleChange = (_: SyntheticEvent, newValue: any): void => {
    setUser(newValue);
    onChange?.(newValue);
  };

  useEffect(() => {
    setUser(defaultState(value, multiple));

    // eslint-disable-next-line
  }, [value]);

  return (
    <Autocomplete
      value={user}
      onChange={handleChange}
      loading={isLoading}
      options={data?.list || []}
      getOptionLabel={(option: User) => option.realName}
      isOptionEqualToValue={(option, value) => option.userId === value.userId}
      renderInput={p => <TextField {...p} {...textFieldProps} label={props.label ?? "選擇人員"} />}
      multiple={multiple}
      renderOption={(props, option) => (
        <li {...props}>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Typography>{option.realName}</Typography>
            <Typography variant="body2">{option?.department?.name ?? ""}</Typography>
          </Stack>
        </li>
      )}
      {...props}
    />
  );
}

import { useState, useCallback } from "react";
import { toast } from "react-toastify";
/**
 * @deprecated - please try libs/notify instead
 */
export default function useSubmit() {
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = useCallback(async (...handlerFuncs) => {
    setIsPending(true);
    toast.dark("處理中");
    let arg = null;
    try {
      for (const handlerFunc of handlerFuncs) {
        arg = handlerFunc ? await handlerFunc(arg) : null;
      }
      arg = null;
      toast.dismiss();
      toast.success("操作成功", { theme: "colored" });
    } catch (err) {
      toast.dismiss();
      toast.error(err.message, { theme: "colored" });
    } finally {
      setIsPending(false);
    }
  }, []);

  return [isPending, handleSubmit];
}

import { Autocomplete } from '@mui/material';
import { useMemberService } from 'service';

export default function SelectMemberGender(props) {
  const { useMemberGenderOpts } = useMemberService();
  const { data: memberGenderData } = useMemberGenderOpts();

  return (
    <Autocomplete
      options={memberGenderData || []}
      getOptionLabel={option => option.genderName}
      {...props}
    />
  );
}

import { useMemo } from 'react';
import { Autocomplete } from '@mui/material';
import { TOWN_ZIPCODE_MAP } from 'data/addressData';

export default function SelectZip({ preSelectCity, ...props }) {
  const options = useMemo(() => {
    if (!preSelectCity) return Object.keys(TOWN_ZIPCODE_MAP);

    return [Object.keys(TOWN_ZIPCODE_MAP).find(key => TOWN_ZIPCODE_MAP[key] === preSelectCity)];
  }, [preSelectCity]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option}
      freeSolo
      isOptionEqualToValue={(option, value) => {
        if (value === '') return false;

        return option === value;
      }}
      {...props}
    />
  );
}

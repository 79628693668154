import { useMemo } from 'react';
import { Autocomplete } from '@mui/material';
import { COUNTY_TOWN_MAP, TOWN_ZIPCODE_MAP } from 'data/addressData';

export default function SelectCity({ preSelectZip, preSelectCounty, ...props }) {
  const options = useMemo(() => {
    if (!preSelectZip && !preSelectCounty) return Object.values(TOWN_ZIPCODE_MAP);

    if (preSelectZip) {
      return [TOWN_ZIPCODE_MAP[preSelectZip]];
    }

    if (preSelectCounty) {
      return COUNTY_TOWN_MAP[preSelectCounty];
    }

    return [];
  }, [preSelectZip, preSelectCounty]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={option => option}
      freeSolo
      isOptionEqualToValue={(option, value) => {
        if (value === '') return false;

        return option === value;
      }}
      {...props}
    />
  );
}

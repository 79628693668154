import { useState, forwardRef } from 'react';
import { Autocomplete, LinearProgress, TextField, Stack, Typography } from '@mui/material';
import { useStockMasterService } from 'service';

const SelectStockMaster = forwardRef(function SelectStockMaster({ onChange }, ref) {
  const { useAllStockMaster } = useStockMasterService();
  const [key, setKey] = useState('');
  const { data: stock, isLoading } = useAllStockMaster({
    key,
    limit: 50,
  });

  const handleChange = (e, nv) => {
    onChange?.(nv);
  };

  return (
    <Autocomplete
      options={stock?.list || []}
      getOptionLabel={option => option.description}
      renderInput={p => <TextField {...p} label="添加項目 (名稱 / 代號 / 國際條碼)" inputRef={ref} />}
      renderOption={(p, opt) => (
        <Stack as="li" {...p} key={opt.stockId} direction="row" gap={2}>
          <Typography variant="body2" flex="1">
            {opt.description}
          </Typography>
          <Stack direction="row" justifyContent="flex-end" flex="1">
            <Typography variant="body2" flex="1" textAlign="right">
              {opt.internationalBarcode}
            </Typography>
            <Typography variant="body2" flex="1" textAlign="right">
              {opt.stockId}
            </Typography>
            <Typography variant="body2" flex="0.2" textAlign="right">
              ${opt?.price?.price || 0}
            </Typography>
          </Stack>
        </Stack>
      )}
      filterOptions={(options, state) => options}
      autoHighlight
      blurOnSelect
      openOnFocus
      loading={isLoading}
      loadingText={<LinearProgress color="warning" />}
      noOptionsText="查無資料"
      value={null}
      onChange={handleChange}
      inputValue={key}
      onInputChange={(e, nv) => setKey(nv)}
    />
  );
});

export default SelectStockMaster;

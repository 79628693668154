import { Autocomplete } from '@mui/material';
import { useHumanResourcesLeaveService } from 'service';

export default function SelectLeaveType(props) {
  const { useLeaveType } = useHumanResourcesLeaveService();
  const { data: leaveType } = useLeaveType();

  return (
    <Autocomplete
      options={leaveType?.list || []}
      getOptionLabel={option => option.leaveTypeName}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      {...props}
    />
  );
}

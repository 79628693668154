export const ADDRESS = [
  {
    countyName: "台北市",
    towns: [
      { zipCode: "100", townName: "中正區" },
      { zipCode: "103", townName: "大同區" },
      { zipCode: "104", townName: "中山區" },
      { zipCode: "105", townName: "松山區" },
      { zipCode: "106", townName: "大安區" },
      { zipCode: "108", townName: "萬華區" },
      { zipCode: "110", townName: "信義區" },
      { zipCode: "111", townName: "士林區" },
      { zipCode: "112", townName: "北投區" },
      { zipCode: "114", townName: "內湖區" },
      { zipCode: "115", townName: "南港區" },
      { zipCode: "116", townName: "文山區" },
    ],
  },
  {
    countyName: "基隆市",
    towns: [
      { zipCode: "200", townName: "仁愛區" },
      { zipCode: "201", townName: "信義區" },
      { zipCode: "202", townName: "中正區" },
      { zipCode: "203", townName: "中山區" },
      { zipCode: "204", townName: "安樂區" },
      { zipCode: "205", townName: "暖暖區" },
      { zipCode: "206", townName: "七堵區" },
    ],
  },
  {
    countyName: "新北市",
    towns: [
      { zipCode: "207", townName: "萬里區" },
      { zipCode: "208", townName: "金山區" },
      { zipCode: "220", townName: "板橋區" },
      { zipCode: "221", townName: "汐止區" },
      { zipCode: "222", townName: "深坑區" },
      { zipCode: "223", townName: "石碇區" },
      { zipCode: "224", townName: "瑞芳區" },
      { zipCode: "226", townName: "平溪區" },
      { zipCode: "227", townName: "雙溪區" },
      { zipCode: "228", townName: "貢寮區" },
      { zipCode: "231", townName: "新店區" },
      { zipCode: "232", townName: "坪林區" },
      { zipCode: "233", townName: "烏來區" },
      { zipCode: "234", townName: "永和區" },
      { zipCode: "235", townName: "中和區" },
      { zipCode: "236", townName: "土城區" },
      { zipCode: "237", townName: "三峽區" },
      { zipCode: "238", townName: "樹林區" },
      { zipCode: "239", townName: "鶯歌區" },
      { zipCode: "241", townName: "三重區" },
      { zipCode: "242", townName: "新莊區" },
      { zipCode: "243", townName: "泰山區" },
      { zipCode: "244", townName: "林口區" },
      { zipCode: "247", townName: "蘆洲區" },
      { zipCode: "248", townName: "五股區" },
      { zipCode: "249", townName: "八里區" },
      { zipCode: "251", townName: "淡水區" },
      { zipCode: "252", townName: "三芝區" },
      { zipCode: "253", townName: "石門區" },
    ],
  },
  {
    countyName: "宜蘭縣",
    towns: [
      { zipCode: "260", townName: "宜蘭市" },
      { zipCode: "261", townName: "頭城鎮" },
      { zipCode: "262", townName: "礁溪鄉" },
      { zipCode: "263", townName: "壯圍鄉" },
      { zipCode: "264", townName: "員山鄉" },
      { zipCode: "265", townName: "羅東鎮" },
      { zipCode: "266", townName: "三星鄉" },
      { zipCode: "267", townName: "大同鄉" },
      { zipCode: "268", townName: "五結鄉" },
      { zipCode: "269", townName: "冬山鄉" },
      { zipCode: "270", townName: "蘇澳鎮" },
      { zipCode: "272", townName: "南澳鄉" },
    ],
  },
  {
    countyName: "新竹市",
    towns: [
      { zipCode: "300", townName: "東區" },
      { zipCode: "300", townName: "北區" },
      { zipCode: "300", townName: "香山區" },
    ],
  },
  {
    countyName: "新竹縣",
    towns: [
      { zipCode: "302", townName: "竹北市" },
      { zipCode: "303", townName: "湖口鄉" },
      { zipCode: "304", townName: "新豐鄉" },
      { zipCode: "305", townName: "新埔鎮" },
      { zipCode: "306", townName: "關西鎮" },
      { zipCode: "307", townName: "芎林鄉" },
      { zipCode: "308", townName: "寶山鄉" },
      { zipCode: "310", townName: "竹東鎮" },
      { zipCode: "311", townName: "五峰鄉" },
      { zipCode: "312", townName: "橫山鄉" },
      { zipCode: "313", townName: "尖石鄉" },
      { zipCode: "314", townName: "北埔鄉" },
      { zipCode: "315", townName: "峨眉鄉" },
    ],
  },
  {
    countyName: "桃園市",
    towns: [
      { zipCode: "320", townName: "中壢區" },
      { zipCode: "324", townName: "平鎮區" },
      { zipCode: "325", townName: "龍潭區" },
      { zipCode: "326", townName: "楊梅區" },
      { zipCode: "327", townName: "新屋區" },
      { zipCode: "328", townName: "觀音區" },
      { zipCode: "330", townName: "桃園區" },
      { zipCode: "333", townName: "龜山區" },
      { zipCode: "334", townName: "八德區" },
      { zipCode: "335", townName: "大溪區" },
      { zipCode: "336", townName: "復興區" },
      { zipCode: "337", townName: "大園區" },
      { zipCode: "338", townName: "蘆竹區" },
    ],
  },
  {
    countyName: "苗栗縣",
    towns: [
      { zipCode: "350", townName: "竹南鎮" },
      { zipCode: "351", townName: "頭份市" },
      { zipCode: "352", townName: "三灣鄉" },
      { zipCode: "353", townName: "南庄鄉" },
      { zipCode: "354", townName: "獅潭鄉" },
      { zipCode: "356", townName: "後龍鎮" },
      { zipCode: "357", townName: "通霄鎮" },
      { zipCode: "358", townName: "苑裡鎮" },
      { zipCode: "360", townName: "苗栗市" },
      { zipCode: "361", townName: "造橋鄉" },
      { zipCode: "362", townName: "頭屋鄉" },
      { zipCode: "363", townName: "公館鄉" },
      { zipCode: "364", townName: "大湖鄉" },
      { zipCode: "365", townName: "泰安鄉" },
      { zipCode: "366", townName: "銅鑼鄉" },
      { zipCode: "367", townName: "三義鄉" },
      { zipCode: "368", townName: "西湖鄉" },
      { zipCode: "369", townName: "卓蘭鎮" },
    ],
  },
  {
    countyName: "臺中市",
    towns: [
      { zipCode: "400", townName: "中區" },
      { zipCode: "401", townName: "東區" },
      { zipCode: "402", townName: "南區" },
      { zipCode: "403", townName: "西區" },
      { zipCode: "404", townName: "北區" },
      { zipCode: "406", townName: "北屯區" },

      { zipCode: "407", townName: "西屯區" },
      { zipCode: "408", townName: "南屯區" },
      { zipCode: "411", townName: "太平區" },
      { zipCode: "412", townName: "大里區" },
      { zipCode: "413", townName: "霧峰區" },
      { zipCode: "414", townName: "烏日區" },
      { zipCode: "420", townName: "豐原區" },
      { zipCode: "421", townName: "后里區" },
      { zipCode: "422", townName: "石岡區" },
      { zipCode: "423", townName: "東勢區" },
      { zipCode: "424", townName: "和平區" },
      { zipCode: "426", townName: "新社區" },
      { zipCode: "427", townName: "潭子區" },
      { zipCode: "428", townName: "大雅區" },
      { zipCode: "429", townName: "神岡區" },
      { zipCode: "432", townName: "大肚區" },
      { zipCode: "433", townName: "沙鹿區" },
      { zipCode: "434", townName: "龍井區" },
      { zipCode: "435", townName: "梧棲區" },
      { zipCode: "436", townName: "清水區" },
      { zipCode: "437", townName: "大甲區" },
      { zipCode: "438", townName: "外埔區" },
      { zipCode: "439", townName: "大安區" },
    ],
  },
  {
    countyName: "彰化縣",
    towns: [
      { zipCode: "500", townName: "彰化市" },
      { zipCode: "502", townName: "芬園鄉" },
      { zipCode: "503", townName: "花壇鄉" },
      { zipCode: "504", townName: "秀水鄉" },
      { zipCode: "505", townName: "鹿港鎮" },
      { zipCode: "506", townName: "福興鄉" },
      { zipCode: "507", townName: "線西鄉" },
      { zipCode: "508", townName: "和美鎮" },
      { zipCode: "509", townName: "伸港鄉" },
      { zipCode: "510", townName: "員林鎮" },
      { zipCode: "511", townName: "社頭鄉" },
      { zipCode: "512", townName: "永靖鄉" },
      { zipCode: "513", townName: "埔心鄉" },
      { zipCode: "514", townName: "溪湖鎮" },
      { zipCode: "515", townName: "大村鄉" },
      { zipCode: "516", townName: "埔鹽鄉" },
      { zipCode: "520", townName: "田中鎮" },
      { zipCode: "521", townName: "北斗鎮" },
      { zipCode: "522", townName: "田尾鄉" },
      { zipCode: "523", townName: "埤頭鄉" },
      { zipCode: "524", townName: "溪州鄉" },
      { zipCode: "525", townName: "竹塘鄉" },
      { zipCode: "526", townName: "二林鎮" },
      { zipCode: "527", townName: "大城鄉" },
      { zipCode: "528", townName: "芳苑鄉" },
      { zipCode: "530", townName: "二水鄉" },
    ],
  },
  {
    countyName: "南投縣",
    towns: [
      { zipCode: "540", townName: "南投市" },
      { zipCode: "541", townName: "中寮鄉" },
      { zipCode: "542", townName: "草屯鎮" },
      { zipCode: "544", townName: "國姓鄉" },
      { zipCode: "545", townName: "埔里鎮" },
      { zipCode: "546", townName: "仁愛鄉" },
      { zipCode: "551", townName: "名間鄉" },
      { zipCode: "552", townName: "集集鎮" },
      { zipCode: "553", townName: "水里鄉" },
      { zipCode: "555", townName: "魚池鄉" },
      { zipCode: "556", townName: "信義鄉" },
      { zipCode: "557", townName: "竹山鎮" },
      { zipCode: "558", townName: "鹿谷鄉" },
    ],
  },
  {
    countyName: "嘉義市",
    towns: [
      { zipCode: "600", townName: "東區" },
      { zipCode: "600", townName: "西區" },
    ],
  },
  {
    countyName: "嘉義縣",
    towns: [
      { zipCode: "602", townName: "番路鄉" },
      { zipCode: "603", townName: "梅山鄉" },
      { zipCode: "604", townName: "竹崎鄉" },
      { zipCode: "605", townName: "阿里山鄉" },
      { zipCode: "606", townName: "中埔鄉" },
      { zipCode: "611", townName: "大埔鄉" },
      { zipCode: "612", townName: "水上鄉" },
      { zipCode: "613", townName: "鹿草鄉" },
      { zipCode: "614", townName: "太保市" },
      { zipCode: "615", townName: "朴子市" },
      { zipCode: "616", townName: "東石鄉" },
      { zipCode: "621", townName: "六腳鄉" },
      { zipCode: "622", townName: "新港鄉" },
      { zipCode: "623", townName: "民雄鄉" },
      { zipCode: "624", townName: "大林鎮" },
      { zipCode: "625", townName: "溪口鄉" },
      { zipCode: "626", townName: "義竹鄉" },
      { zipCode: "631", townName: "布袋鎮" },
    ],
  },
  {
    countyName: "雲林縣",
    towns: [
      { zipCode: "630", townName: "斗南鎮" },
      { zipCode: "631", townName: "大埤鄉" },
      { zipCode: "632", townName: "虎尾鎮" },
      { zipCode: "633", townName: "土庫鎮" },
      { zipCode: "634", townName: "褒忠鄉" },
      { zipCode: "635", townName: "東勢鄉" },
      { zipCode: "636", townName: "臺西鄉" },
      { zipCode: "637", townName: "崙背鄉" },
      { zipCode: "638", townName: "麥寮鄉" },
      { zipCode: "640", townName: "斗六市" },
      { zipCode: "643", townName: "林內鄉" },
      { zipCode: "646", townName: "古坑鄉" },
      { zipCode: "647", townName: "莿桐鄉" },
      { zipCode: "648", townName: "西螺鎮" },
      { zipCode: "649", townName: "二崙鄉" },
      { zipCode: "651", townName: "北港鎮" },
      { zipCode: "652", townName: "水林鄉" },
      { zipCode: "653", townName: "口湖鄉" },
      { zipCode: "654", townName: "四湖鄉" },
      { zipCode: "655", townName: "元長鄉" },
    ],
  },
  {
    countyName: "臺南市",
    towns: [
      { zipCode: "700", townName: "中西區" },
      { zipCode: "701", townName: "東區" },
      { zipCode: "702", townName: "南區" },
      { zipCode: "704", townName: "北區" },
      { zipCode: "708", townName: "安平區" },
      { zipCode: "709", townName: "安南區" },
      { zipCode: "710", townName: "永康區" },
      { zipCode: "711", townName: "歸仁區" },
      { zipCode: "712", townName: "新化區" },
      { zipCode: "713", townName: "左鎮區" },
      { zipCode: "714", townName: "玉井區" },
      { zipCode: "715", townName: "楠西區" },
      { zipCode: "716", townName: "南化區" },
      { zipCode: "717", townName: "仁德區" },
      { zipCode: "718", townName: "關廟區" },
      { zipCode: "719", townName: "龍崎區" },
      { zipCode: "720", townName: "官田區" },
      { zipCode: "721", townName: "麻豆區" },
      { zipCode: "722", townName: "佳里區" },
      { zipCode: "723", townName: "西港區" },
      { zipCode: "724", townName: "七股區" },
      { zipCode: "725", townName: "將軍區" },
      { zipCode: "726", townName: "學甲區" },
      { zipCode: "727", townName: "北門區" },
      { zipCode: "730", townName: "新營區" },
      { zipCode: "731", townName: "後壁區" },
      { zipCode: "732", townName: "白河區" },
      { zipCode: "733", townName: "東山區" },
      { zipCode: "734", townName: "六甲區" },
      { zipCode: "735", townName: "下營區" },
      { zipCode: "736", townName: "柳營區" },
      { zipCode: "737", townName: "鹽水區" },
      { zipCode: "741", townName: "善化區" },
      { zipCode: "742", townName: "大內區" },
      { zipCode: "743", townName: "山上區" },
      { zipCode: "744", townName: "新市區" },
      { zipCode: "745", townName: "安定區" },
    ],
  },
  {
    countyName: "高雄市",
    towns: [
      { zipCode: "800", townName: "新興區" },
      { zipCode: "801", townName: "前金區" },
      { zipCode: "802", townName: "苓雅區" },
      { zipCode: "803", townName: "鹽埕區" },

      { zipCode: "804", townName: "鼓山區" },
      { zipCode: "805", townName: "旗津區" },
      { zipCode: "806", townName: "前鎮區" },
      { zipCode: "807", townName: "三民區" },
      { zipCode: "811", townName: "楠梓區" },
      { zipCode: "812", townName: "小港區" },
      { zipCode: "813", townName: "左營區" },
      { zipCode: "814", townName: "仁武區" },
      { zipCode: "815", townName: "大社區" },
      { zipCode: "820", townName: "岡山區" },
      { zipCode: "821", townName: "路竹區" },
      { zipCode: "822", townName: "阿蓮區" },
      { zipCode: "823", townName: "田寮區" },
      { zipCode: "824", townName: "燕巢區" },
      { zipCode: "825", townName: "橋頭區" },
      { zipCode: "826", townName: "梓官區" },
      { zipCode: "827", townName: "彌陀區" },
      { zipCode: "828", townName: "永安區" },
      { zipCode: "829", townName: "湖內區" },
      { zipCode: "830", townName: "鳳山區" },
      { zipCode: "831", townName: "大寮區" },
      { zipCode: "832", townName: "林園區" },
      { zipCode: "833", townName: "鳥松區" },
      { zipCode: "840", townName: "大樹區" },
      { zipCode: "842", townName: "旗山區" },
      { zipCode: "843", townName: "美濃區" },
      { zipCode: "844", townName: "六龜區" },
      { zipCode: "845", townName: "內門區" },
      { zipCode: "846", townName: "杉林區" },
      { zipCode: "847", townName: "甲仙區" },
      { zipCode: "848", townName: "桃源區" },
      { zipCode: "849", townName: "那瑪夏區" },
      { zipCode: "851", townName: "茂林區" },
      { zipCode: "852", townName: "茄萣區" },
    ],
  },
  {
    countyName: "澎湖縣",
    towns: [
      { zipCode: "880", townName: "馬公市" },
      { zipCode: "881", townName: "西嶼鄉" },
      { zipCode: "882", townName: "望安鄉" },
      { zipCode: "883", townName: "七美鄉" },
      { zipCode: "884", townName: "白沙鄉" },
      { zipCode: "885", townName: "湖西鄉" },
    ],
  },
  {
    countyName: "屏東縣",
    towns: [
      { zipCode: "900", townName: "屏東市" },
      { zipCode: "901", townName: "三地門鄉" },
      { zipCode: "902", townName: "霧臺鄉" },
      { zipCode: "903", townName: "瑪家鄉" },
      { zipCode: "904", townName: "九如鄉" },
      { zipCode: "905", townName: "里港鄉" },
      { zipCode: "906", townName: "高樹鄉" },
      { zipCode: "907", townName: "鹽埔鄉" },
      { zipCode: "908", townName: "長治鄉" },
      { zipCode: "909", townName: "麟洛鄉" },
      { zipCode: "911", townName: "竹田鄉" },
      { zipCode: "912", townName: "內埔鄉" },
      { zipCode: "913", townName: "萬丹鄉" },
      { zipCode: "920", townName: "潮州鎮" },
      { zipCode: "921", townName: "泰武鄉" },
      { zipCode: "922", townName: "來義鄉" },
      { zipCode: "923", townName: "萬巒鄉" },
      { zipCode: "924", townName: "崁頂鄉" },
      { zipCode: "925", townName: "新埤鄉" },
      { zipCode: "926", townName: "南州鄉" },
      { zipCode: "927", townName: "林邊鄉" },
      { zipCode: "928", townName: "東港鎮" },
      { zipCode: "929", townName: "琉球鄉" },
      { zipCode: "931", townName: "佳冬鄉" },
      { zipCode: "932", townName: "新園鄉" },
      { zipCode: "940", townName: "枋寮鄉" },
      { zipCode: "941", townName: "枋山鄉" },
      { zipCode: "942", townName: "春日鄉" },
      { zipCode: "943", townName: "獅子鄉" },
      { zipCode: "944", townName: "車城鄉" },
      { zipCode: "945", townName: "牡丹鄉" },
      { zipCode: "946", townName: "恆春鎮" },
      { zipCode: "947", townName: "滿州鄉" },
    ],
  },
  {
    countyName: "臺東縣",
    towns: [
      { zipCode: "950", townName: "臺東市" },
      { zipCode: "951", townName: "綠島鄉" },
      { zipCode: "952", townName: "蘭嶼鄉" },
      { zipCode: "953", townName: "延平鄉" },
      { zipCode: "954", townName: "卑南鄉" },
      { zipCode: "955", townName: "鹿野鄉" },
      { zipCode: "956", townName: "關山鎮" },
      { zipCode: "957", townName: "海端鄉" },
      { zipCode: "958", townName: "池上鄉" },
      { zipCode: "959", townName: "東河鄉" },
      { zipCode: "961", townName: "成功鎮" },
      { zipCode: "962", townName: "長濱鄉" },
      { zipCode: "963", townName: "太麻里鄉" },
      { zipCode: "964", townName: "金峰鄉" },
      { zipCode: "965", townName: "大武鄉" },
      { zipCode: "966", townName: "達仁鄉" },
    ],
  },
  {
    countyName: "花蓮縣",
    towns: [
      { zipCode: "970", townName: "花蓮市" },
      { zipCode: "971", townName: "新城鄉" },
      { zipCode: "972", townName: "秀林鄉" },
      { zipCode: "973", townName: "吉安鄉" },
      { zipCode: "974", townName: "壽豐鄉" },
      { zipCode: "975", townName: "鳳林鎮" },
      { zipCode: "976", townName: "光復鄉" },
      { zipCode: "977", townName: "豐濱鄉" },
      { zipCode: "978", townName: "瑞穗鄉" },
      { zipCode: "979", townName: "萬榮鄉" },
      { zipCode: "981", townName: "玉里鎮" },
      { zipCode: "982", townName: "卓溪鄉" },
      { zipCode: "983", townName: "富里鄉" },
    ],
  },
  {
    countyName: "金門縣",
    towns: [
      { zipCode: "890", townName: "金沙鎮" },
      { zipCode: "891", townName: "金湖鎮" },
      { zipCode: "892", townName: "金寧鄉" },
      { zipCode: "893", townName: "金城鎮" },
      { zipCode: "894", townName: "烈嶼鄉" },
      { zipCode: "896", townName: "烏坵鄉" },
    ],
  },
  {
    countyName: "連江縣",
    towns: [
      { zipCode: "209", townName: "南竿鄉" },
      { zipCode: "210", townName: "北竿鄉" },
      { zipCode: "211", townName: "莒光鄉" },
      { zipCode: "212", townName: "東引鄉" },
    ],
  },
];

export const COUNTY_LIST = [
  "台北市",
  "基隆市",
  "新北市",
  "宜蘭縣",
  "新竹市",
  "新竹縣",
  "桃園市",
  "苗栗縣",
  "臺中市",
  "彰化縣",
  "南投縣",
  "嘉義市",
  "嘉義縣",
  "雲林縣",
  "臺南市",
  "高雄市",
  "澎湖縣",
  "屏東縣",
  "臺東縣",
  "花蓮縣",
  "金門縣",
  "連江縣",
];

export const COUNTY_TOWN_MAP = {
  台北市: ["中正區", "大同區", "中山區", "松山區", "大安區", "萬華區", "信義區", "士林區", "北投區", "內湖區", "南港區", "文山區"],
  基隆市: ["仁愛區", "信義區", "中正區", "中山區", "安樂區", "暖暖區", "七堵區"],
  新北市: [
    "萬里區",
    "金山區",
    "板橋區",
    "汐止區",
    "深坑區",
    "石碇區",
    "瑞芳區",
    "平溪區",
    "雙溪區",
    "貢寮區",
    "新店區",
    "坪林區",
    "烏來區",
    "永和區",
    "中和區",
    "土城區",
    "三峽區",
    "樹林區",
    "鶯歌區",
    "三重區",
    "新莊區",
    "泰山區",
    "林口區",
    "蘆洲區",
    "五股區",
    "八里區",
    "淡水區",
    "三芝區",
    "石門區",
  ],
  宜蘭縣: ["宜蘭市", "頭城鎮", "礁溪鄉", "壯圍鄉", "員山鄉", "羅東鎮", "三星鄉", "大同鄉", "五結鄉", "冬山鄉", "蘇澳鎮", "南澳鄉"],
  新竹市: ["東區", "北區", "香山區"],
  新竹縣: [
    "竹北市",
    "湖口鄉",
    "新豐鄉",
    "新埔鎮",
    "關西鎮",
    "芎林鄉",
    "寶山鄉",
    "竹東鎮",
    "五峰鄉",
    "橫山鄉",
    "尖石鄉",
    "北埔鄉",
    "峨眉鄉",
  ],
  桃園市: [
    "中壢區",
    "平鎮區",
    "龍潭區",
    "楊梅區",
    "新屋區",
    "觀音區",
    "桃園區",
    "龜山區",
    "八德區",
    "大溪區",
    "復興區",
    "大園區",
    "蘆竹區",
  ],
  苗栗縣: [
    "竹南鎮",
    "頭份市",
    "三灣鄉",
    "南庄鄉",
    "獅潭鄉",
    "後龍鎮",
    "通霄鎮",
    "苑裡鎮",
    "苗栗市",
    "造橋鄉",
    "頭屋鄉",
    "公館鄉",
    "大湖鄉",
    "泰安鄉",
    "銅鑼鄉",
    "三義鄉",
    "西湖鄉",
    "卓蘭鎮",
  ],
  臺中市: [
    "中區",
    "東區",
    "南區",
    "西區",
    "北區",
    "北屯區",
    "西屯區",
    "南屯區",
    "太平區",
    "大里區",
    "霧峰區",
    "烏日區",
    "豐原區",
    "后里區",
    "石岡區",
    "東勢區",
    "和平區",
    "新社區",
    "潭子區",
    "大雅區",
    "神岡區",
    "大肚區",
    "沙鹿區",
    "龍井區",
    "梧棲區",
    "清水區",
    "大甲區",
    "外埔區",
    "大安區",
  ],
  彰化縣: [
    "彰化市",
    "芬園鄉",
    "花壇鄉",
    "秀水鄉",
    "鹿港鎮",
    "福興鄉",
    "線西鄉",
    "和美鎮",
    "伸港鄉",
    "員林鎮",
    "社頭鄉",
    "永靖鄉",
    "埔心鄉",
    "溪湖鎮",
    "大村鄉",
    "埔鹽鄉",
    "田中鎮",
    "北斗鎮",
    "田尾鄉",
    "埤頭鄉",
    "溪州鄉",
    "竹塘鄉",
    "二林鎮",
    "大城鄉",
    "芳苑鄉",
    "二水鄉",
  ],
  南投縣: [
    "南投市",
    "中寮鄉",
    "草屯鎮",
    "國姓鄉",
    "埔里鎮",
    "仁愛鄉",
    "名間鄉",
    "集集鎮",
    "水里鄉",
    "魚池鄉",
    "信義鄉",
    "竹山鎮",
    "鹿谷鄉",
  ],
  嘉義市: ["東區", "西區"],
  嘉義縣: [
    "番路鄉",
    "梅山鄉",
    "竹崎鄉",
    "阿里山鄉",
    "中埔鄉",
    "大埔鄉",
    "水上鄉",
    "鹿草鄉",
    "太保市",
    "朴子市",
    "東石鄉",
    "六腳鄉",
    "新港鄉",
    "民雄鄉",
    "大林鎮",
    "溪口鄉",
    "義竹鄉",
    "布袋鎮",
  ],
  雲林縣: [
    "斗南鎮",
    "大埤鄉",
    "虎尾鎮",
    "土庫鎮",
    "褒忠鄉",
    "東勢鄉",
    "臺西鄉",
    "崙背鄉",
    "麥寮鄉",
    "斗六市",
    "林內鄉",
    "古坑鄉",
    "莿桐鄉",
    "西螺鎮",
    "二崙鄉",
    "北港鎮",
    "水林鄉",
    "口湖鄉",
    "四湖鄉",
    "元長鄉",
  ],
  臺南市: [
    "中西區",
    "東區",
    "南區",
    "北區",
    "安平區",
    "安南區",
    "永康區",
    "歸仁區",
    "新化區",
    "左鎮區",
    "玉井區",
    "楠西區",
    "南化區",
    "仁德區",
    "關廟區",
    "龍崎區",
    "官田區",
    "麻豆區",
    "佳里區",
    "西港區",
    "七股區",
    "將軍區",
    "學甲區",
    "北門區",
    "新營區",
    "後壁區",
    "白河區",
    "東山區",
    "六甲區",
    "下營區",
    "柳營區",
    "鹽水區",
    "善化區",
    "大內區",
    "山上區",
    "新市區",
    "安定區",
  ],
  高雄市: [
    "新興區",
    "前金區",
    "苓雅區",
    "鹽埕區",
    "鼓山區",
    "旗津區",
    "前鎮區",
    "三民區",
    "楠梓區",
    "小港區",
    "左營區",
    "仁武區",
    "大社區",
    "岡山區",
    "路竹區",
    "阿蓮區",
    "田寮區",
    "燕巢區",
    "橋頭區",
    "梓官區",
    "彌陀區",
    "永安區",
    "湖內區",
    "鳳山區",
    "大寮區",
    "林園區",
    "鳥松區",
    "大樹區",
    "旗山區",
    "美濃區",
    "六龜區",
    "內門區",
    "杉林區",
    "甲仙區",
    "桃源區",
    "那瑪夏區",
    "茂林區",
    "茄萣區",
  ],
  澎湖縣: ["馬公市", "西嶼鄉", "望安鄉", "七美鄉", "白沙鄉", "湖西鄉"],
  屏東縣: [
    "屏東市",
    "三地門鄉",
    "霧臺鄉",
    "瑪家鄉",
    "九如鄉",
    "里港鄉",
    "高樹鄉",
    "鹽埔鄉",
    "長治鄉",
    "麟洛鄉",
    "竹田鄉",
    "內埔鄉",
    "萬丹鄉",
    "潮州鎮",
    "泰武鄉",
    "來義鄉",
    "萬巒鄉",
    "崁頂鄉",
    "新埤鄉",
    "南州鄉",
    "林邊鄉",
    "東港鎮",
    "琉球鄉",
    "佳冬鄉",
    "新園鄉",
    "枋寮鄉",
    "枋山鄉",
    "春日鄉",
    "獅子鄉",
    "車城鄉",
    "牡丹鄉",
    "恆春鎮",
    "滿州鄉",
  ],
  臺東縣: [
    "臺東市",
    "綠島鄉",
    "蘭嶼鄉",
    "延平鄉",
    "卑南鄉",
    "鹿野鄉",
    "關山鎮",
    "海端鄉",
    "池上鄉",
    "東河鄉",
    "成功鎮",
    "長濱鄉",
    "太麻里鄉",
    "金峰鄉",
    "大武鄉",
    "達仁鄉",
  ],
  花蓮縣: [
    "花蓮市",
    "新城鄉",
    "秀林鄉",
    "吉安鄉",
    "壽豐鄉",
    "鳳林鎮",
    "光復鄉",
    "豐濱鄉",
    "瑞穗鄉",
    "萬榮鄉",
    "玉里鎮",
    "卓溪鄉",
    "富里鄉",
  ],
  金門縣: ["金沙鎮", "金湖鎮", "金寧鄉", "金城鎮", "烈嶼鄉", "烏坵鄉"],
  連江縣: ["南竿鄉", "北竿鄉", "莒光鄉", "東引鄉"],
};

export const TOWN_ZIPCODE_MAP = {
  100: "中正區",
  103: "大同區",
  104: "中山區",
  105: "松山區",
  106: "大安區",
  108: "萬華區",
  110: "信義區",
  111: "士林區",
  112: "北投區",
  114: "內湖區",
  115: "南港區",
  116: "文山區",
  200: "仁愛區",
  201: "信義區",
  202: "中正區",
  203: "中山區",
  204: "安樂區",
  205: "暖暖區",
  206: "七堵區",
  207: "萬里區",
  208: "金山區",
  209: "南竿鄉",
  210: "北竿鄉",
  211: "莒光鄉",
  212: "東引鄉",
  220: "板橋區",
  221: "汐止區",
  222: "深坑區",
  223: "石碇區",
  224: "瑞芳區",
  226: "平溪區",
  227: "雙溪區",
  228: "貢寮區",
  231: "新店區",
  232: "坪林區",
  233: "烏來區",
  234: "永和區",
  235: "中和區",
  236: "土城區",
  237: "三峽區",
  238: "樹林區",
  239: "鶯歌區",
  241: "三重區",
  242: "新莊區",
  243: "泰山區",
  244: "林口區",
  247: "蘆洲區",
  248: "五股區",
  249: "八里區",
  251: "淡水區",
  252: "三芝區",
  253: "石門區",
  260: "宜蘭市",
  261: "頭城鎮",
  262: "礁溪鄉",
  263: "壯圍鄉",
  264: "員山鄉",
  265: "羅東鎮",
  266: "三星鄉",
  267: "大同鄉",
  268: "五結鄉",
  269: "冬山鄉",
  270: "蘇澳鎮",
  272: "南澳鄉",
  300: "香山區",
  302: "竹北市",
  303: "湖口鄉",
  304: "新豐鄉",
  305: "新埔鎮",
  306: "關西鎮",
  307: "芎林鄉",
  308: "寶山鄉",
  310: "竹東鎮",
  311: "五峰鄉",
  312: "橫山鄉",
  313: "尖石鄉",
  314: "北埔鄉",
  315: "峨眉鄉",
  320: "中壢區",
  324: "平鎮區",
  325: "龍潭區",
  326: "楊梅區",
  327: "新屋區",
  328: "觀音區",
  330: "桃園區",
  333: "龜山區",
  334: "八德區",
  335: "大溪區",
  336: "復興區",
  337: "大園區",
  338: "蘆竹區",
  350: "竹南鎮",
  351: "頭份市",
  352: "三灣鄉",
  353: "南庄鄉",
  354: "獅潭鄉",
  356: "後龍鎮",
  357: "通霄鎮",
  358: "苑裡鎮",
  360: "苗栗市",
  361: "造橋鄉",
  362: "頭屋鄉",
  363: "公館鄉",
  364: "大湖鄉",
  365: "泰安鄉",
  366: "銅鑼鄉",
  367: "三義鄉",
  368: "西湖鄉",
  369: "卓蘭鎮",
  400: "中區",
  401: "東區",
  402: "南區",
  403: "西區",
  404: "北區",
  406: "北屯區",
  407: "西屯區",
  408: "南屯區",
  411: "太平區",
  412: "大里區",
  413: "霧峰區",
  414: "烏日區",
  420: "豐原區",
  421: "后里區",
  422: "石岡區",
  423: "東勢區",
  424: "和平區",
  426: "新社區",
  427: "潭子區",
  428: "大雅區",
  429: "神岡區",
  432: "大肚區",
  433: "沙鹿區",
  434: "龍井區",
  435: "梧棲區",
  436: "清水區",
  437: "大甲區",
  438: "外埔區",
  439: "大安區",
  500: "彰化市",
  502: "芬園鄉",
  503: "花壇鄉",
  504: "秀水鄉",
  505: "鹿港鎮",
  506: "福興鄉",
  507: "線西鄉",
  508: "和美鎮",
  509: "伸港鄉",
  510: "員林鎮",
  511: "社頭鄉",
  512: "永靖鄉",
  513: "埔心鄉",
  514: "溪湖鎮",
  515: "大村鄉",
  516: "埔鹽鄉",
  520: "田中鎮",
  521: "北斗鎮",
  522: "田尾鄉",
  523: "埤頭鄉",
  524: "溪州鄉",
  525: "竹塘鄉",
  526: "二林鎮",
  527: "大城鄉",
  528: "芳苑鄉",
  530: "二水鄉",
  540: "南投市",
  541: "中寮鄉",
  542: "草屯鎮",
  544: "國姓鄉",
  545: "埔里鎮",
  546: "仁愛鄉",
  551: "名間鄉",
  552: "集集鎮",
  553: "水里鄉",
  555: "魚池鄉",
  556: "信義鄉",
  557: "竹山鎮",
  558: "鹿谷鄉",
  600: "西區",
  602: "番路鄉",
  603: "梅山鄉",
  604: "竹崎鄉",
  605: "阿里山鄉",
  606: "中埔鄉",
  611: "大埔鄉",
  612: "水上鄉",
  613: "鹿草鄉",
  614: "太保市",
  615: "朴子市",
  616: "東石鄉",
  621: "六腳鄉",
  622: "新港鄉",
  623: "民雄鄉",
  624: "大林鎮",
  625: "溪口鄉",
  626: "義竹鄉",
  630: "斗南鎮",
  631: "大埤鄉",
  632: "虎尾鎮",
  633: "土庫鎮",
  634: "褒忠鄉",
  635: "東勢鄉",
  636: "臺西鄉",
  637: "崙背鄉",
  638: "麥寮鄉",
  640: "斗六市",
  643: "林內鄉",
  646: "古坑鄉",
  647: "莿桐鄉",
  648: "西螺鎮",
  649: "二崙鄉",
  651: "北港鎮",
  652: "水林鄉",
  653: "口湖鄉",
  654: "四湖鄉",
  655: "元長鄉",
  700: "中西區",
  701: "東區",
  702: "南區",
  704: "北區",
  708: "安平區",
  709: "安南區",
  710: "永康區",
  711: "歸仁區",
  712: "新化區",
  713: "左鎮區",
  714: "玉井區",
  715: "楠西區",
  716: "南化區",
  717: "仁德區",
  718: "關廟區",
  719: "龍崎區",
  720: "官田區",
  721: "麻豆區",
  722: "佳里區",
  723: "西港區",
  724: "七股區",
  725: "將軍區",
  726: "學甲區",
  727: "北門區",
  730: "新營區",
  731: "後壁區",
  732: "白河區",
  733: "東山區",
  734: "六甲區",
  735: "下營區",
  736: "柳營區",
  737: "鹽水區",
  741: "善化區",
  742: "大內區",
  743: "山上區",
  744: "新市區",
  745: "安定區",
  800: "新興區",
  801: "前金區",
  802: "苓雅區",
  803: "鹽埕區",
  804: "鼓山區",
  805: "旗津區",
  806: "前鎮區",
  807: "三民區",
  811: "楠梓區",
  812: "小港區",
  813: "左營區",
  814: "仁武區",
  815: "大社區",
  820: "岡山區",
  821: "路竹區",
  822: "阿蓮區",
  823: "田寮區",
  824: "燕巢區",
  825: "橋頭區",
  826: "梓官區",
  827: "彌陀區",
  828: "永安區",
  829: "湖內區",
  830: "鳳山區",
  831: "大寮區",
  832: "林園區",
  833: "鳥松區",
  840: "大樹區",
  842: "旗山區",
  843: "美濃區",
  844: "六龜區",
  845: "內門區",
  846: "杉林區",
  847: "甲仙區",
  848: "桃源區",
  849: "那瑪夏區",
  851: "茂林區",
  852: "茄萣區",
  880: "馬公市",
  881: "西嶼鄉",
  882: "望安鄉",
  883: "七美鄉",
  884: "白沙鄉",
  885: "湖西鄉",
  890: "金沙鎮",
  891: "金湖鎮",
  892: "金寧鄉",
  893: "金城鎮",
  894: "烈嶼鄉",
  896: "烏坵鄉",
  900: "屏東市",
  901: "三地門鄉",
  902: "霧臺鄉",
  903: "瑪家鄉",
  904: "九如鄉",
  905: "里港鄉",
  906: "高樹鄉",
  907: "鹽埔鄉",
  908: "長治鄉",
  909: "麟洛鄉",
  911: "竹田鄉",
  912: "內埔鄉",
  913: "萬丹鄉",
  920: "潮州鎮",
  921: "泰武鄉",
  922: "來義鄉",
  923: "萬巒鄉",
  924: "崁頂鄉",
  925: "新埤鄉",
  926: "南州鄉",
  927: "林邊鄉",
  928: "東港鎮",
  929: "琉球鄉",
  931: "佳冬鄉",
  932: "新園鄉",
  940: "枋寮鄉",
  941: "枋山鄉",
  942: "春日鄉",
  943: "獅子鄉",
  944: "車城鄉",
  945: "牡丹鄉",
  946: "恆春鎮",
  947: "滿州鄉",
  950: "臺東市",
  951: "綠島鄉",
  952: "蘭嶼鄉",
  953: "延平鄉",
  954: "卑南鄉",
  955: "鹿野鄉",
  956: "關山鎮",
  957: "海端鄉",
  958: "池上鄉",
  959: "東河鄉",
  961: "成功鎮",
  962: "長濱鄉",
  963: "太麻里鄉",
  964: "金峰鄉",
  965: "大武鄉",
  966: "達仁鄉",
  970: "花蓮市",
  971: "新城鄉",
  972: "秀林鄉",
  973: "吉安鄉",
  974: "壽豐鄉",
  975: "鳳林鎮",
  976: "光復鄉",
  977: "豐濱鄉",
  978: "瑞穗鄉",
  979: "萬榮鄉",
  981: "玉里鎮",
  982: "卓溪鄉",
  983: "富里鄉",
};

export default ADDRESS;

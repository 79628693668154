import { Autocomplete, TextField, Box, Typography, TextFieldProps } from "@mui/material";
import { useCageService } from "service/index";

type Props = {
  value?: null | API.Cage;
  onSelect?(e: React.SyntheticEvent, nv: null | API.Cage): void;
  textFieldProps: TextFieldProps;
};

export default function SelectCage({ value, onSelect, textFieldProps, ...props }: Props) {
  const { useAllCage } = useCageService();
  const { data, isLoading } = useAllCage({ query: new URLSearchParams({ limit: "zero", sort: "zone_id asc, create_time asc" }) });

  return (
    <Autocomplete<API.Cage>
      options={data?.list || []}
      loading={isLoading}
      renderInput={p => <TextField {...p} label="籠位" {...textFieldProps} />}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={option => option.cageName}
      groupBy={option => option.zone.zoneName}
      filterOptions={options => options.filter(opt => !opt.isDisable)}
      renderOption={(props, option, state) => (
        <Box component="li" {...props} gap={1} key={option.id}>
          <Typography marginRight="auto">{option.cageName}</Typography>
          <Typography>{option.cageStyle.cageStyleName}</Typography>
        </Box>
      )}
      value={value}
      onChange={onSelect}
      {...props}
    />
  );
}

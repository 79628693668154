import { Autocomplete } from '@mui/material';
import { useOldPetService } from 'service';

export default function SelectPetVariety({ anonymous, petTypeQuery, ...props }) {
  const { usePetVarietyOpts } = useOldPetService(anonymous);
  const { data: petVarData } = usePetVarietyOpts({ petTypeId: petTypeQuery });

  return (
    <Autocomplete
      options={petVarData?.list || []}
      getOptionLabel={option => option.varietyName}
      noOptionsText="請先選擇類別或無此項目"
      {...props}
    />
  );
}

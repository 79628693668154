import { Autocomplete, TextField, Box, Typography, CircularProgress, AutocompleteProps, TextFieldProps } from "@mui/material";
import { mapValues, omitBy } from "lodash";
import { useEffect, useState } from "react";
import { usePetService } from "service/index";

type Props = {
  value?: null | API.Pet;
  query?: APIQuery.PetQuery;
  onSelect?(e: React.SyntheticEvent, nv: API.Pet | null): void;
  textFieldProps: TextFieldProps;
  [x: string]: any;
};

export default function SelectPet({ value, query, onSelect, textFieldProps, ...props }: Props) {
  const { useAllPet } = usePetService();

  const [name, setName] = useState("");
  const [delayQuery, setDelayQuery] = useState<APIQuery.PetQuery>({});

  const { data, isLoading } = useAllPet({
    query: new URLSearchParams(mapValues({ ...delayQuery, limit: 30 }, o => `${o}`)),
  });

  useEffect(() => {
    const timeout = setTimeout(() => setDelayQuery(omitBy({ ...query, petName: name }, v => v === "" || v == null)), 200);
    return () => clearTimeout(timeout);
  }, [query, name]);

  return (
    <Autocomplete<API.Pet>
      fullWidth
      loading={isLoading}
      options={data?.list || []}
      getOptionLabel={option => option.petName}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={p => (
        <TextField
          {...p}
          // required={required}
          label="選擇寵物"
          helperText={delayQuery.phone ? `已套用電話號碼搜尋：${delayQuery.phone}` : ""}
          InputProps={{
            ...p.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress size={20} /> : null}
                {p.InputProps.endAdornment}
              </>
            ),
          }}
          {...textFieldProps}
        />
      )}
      renderOption={(props, option, state) => (
        <Box component="li" {...props} gap={1} key={option.id}>
          <Typography marginRight="auto">{option.petName}</Typography>
          {option.petType && <Typography>{option.petType.typeName}</Typography>}
          {option.petVariety && <Typography>{option.petVariety.varietyName}</Typography>}
        </Box>
      )}
      filterOptions={x => x}
      inputValue={name}
      onInputChange={(e, nv) => setName(nv)}
      value={value}
      onChange={onSelect}
      {...props}
    />
  );
}
